import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Headroom from "react-headroom";

const HeaderEN = ({ location }) => {
    const [navMode, setNavMode] = useState("header-transparent pt--15");
    const [mobileSubNavMode1, setMobileSubNavMode1] = useState(false);

    useEffect(() => {
        if ((typeof location.pathname.split("/")[3] !== "undefined" && location.pathname.split("/")[3].length > 0) || location.pathname === "/privacy" || location.pathname === "/about") {
            setNavMode("header-dark");
        }
    }, []);

    const toggleMobileMenu = () => {
        let body = document.body;
        body.classList.add("popup-mobile-menu-wrapper");
    };

    const closeMobileMenu = () => {
        let body = document.body;
        body.classList.remove("popup-mobile-menu-wrapper");
    };

    const toggleMobileSubMenu1 = () => {
        setMobileSubNavMode1(!mobileSubNavMode1);
    };

    return (
        <>
            <Headroom style={{ zIndex: 9999 }} disableInlineStyles className={`br_header header-default ${navMode} light-logo--version header-fixed-width headroom--sticky header-mega-menu clearfix`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="header__wrapper mr--0">
                                <div className="header-left flex-20">
                                    <div className="logo">
                                        <Link to={`/en/`}>
                                            <img style={{ height: 80, width: "auto" }} src="/images/logo_02.png" alt="Cottonhat Logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="header-flex-right flex-80">
                                    <div className="mainmenu-wrapper d-none d-lg-block">
                                        <nav className="page_nav">
                                            <ul className="mainmenu">
                                                <li className="level1 slide--megamenu">
                                                    <Link to={`/en/`}>
                                                        <span className="menu-item-title">Home</span>
                                                    </Link>
                                                </li>
                                                <li className="level1 with--drop slide-dropdown">
                                                    <a>
                                                        <span>Case Studies</span>
                                                    </a>
                                                    <ul className={`dropdown__menu`}>
                                                        <li>
                                                            <Link to={`/en/case-studies/`}>
                                                                <span>All</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/en/case-studies/e-commerce/`}>
                                                                <span>E-Commerce</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/en/case-studies/mobile/`}>
                                                                <span>Mobile</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/en/case-studies/custom-development/`}>
                                                                <span>Custom Development</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/en/case-studies/content-creation/`}>
                                                                <span>Content Creation</span>
                                                            </Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to={`/en/case-studies/social-media-management/`}>
                                                                <span>Social Media Management</span>
                                                            </Link>
                                                        </li> */}
                                                    </ul>
                                                </li>
                                                <li className="level1 slide--megamenu">
                                                    <Link to={`/en/blog/`}>
                                                        <span className="menu-item-title">Blog</span>
                                                    </Link>
                                                </li>
                                                <li className="level1 slide--megamenu">
                                                    <Link to={`/en/services/`}>
                                                        <span className="menu-item-title">Services</span>
                                                    </Link>
                                                </li>
                                                <li className="level1 slide--megamenu">
                                                    <Link to={`/en/contact-us/`}>
                                                        <span className="menu-item-title">Contact Us</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="manu-hamber popup-mobile-click d-block d-lg-none black-version d-block d-xl-none" onClick={toggleMobileMenu}>
                                    <div>
                                        <i></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Headroom>
            <div className="popup-mobile-manu popup-mobile-visiable">
                <div className="inner">
                    <div className="mobileheader">
                        <div className="logo">
                            <Link to={`/en/`} onClick={closeMobileMenu}>
                                <img style={{ height: 55, width: 50 }} src="/images/logo_02.png" alt="Cottonhat Logo" />
                            </Link>
                        </div>
                        <a className="mobile-close" onClick={closeMobileMenu}></a>
                    </div>
                    <div className="menu-content">
                        <ul className="menulist object-custom-menu">
                            <li>
                                <Link to={`/en/`} onClick={closeMobileMenu}>
                                    <span>Home</span>
                                </Link>
                            </li>
                            <li className="has-mega-menu ">
                                <a className={`${mobileSubNavMode1 ? "active" : ""}`} onClick={toggleMobileSubMenu1}>
                                    <span>Case Studies</span>
                                </a>
                                <ul className={`object-submenu ${mobileSubNavMode1 ? "is-visiable" : ""}`} style={{ display: mobileSubNavMode1 ? "block" : "none" }}>
                                    <li>
                                        <Link to={`/en/case-studies/`} onClick={closeMobileMenu}>
                                            <span>All</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/en/case-studies/e-commerce`} onClick={closeMobileMenu}>
                                            <span>E-Commerce</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/en/case-studies/mobile`} onClick={closeMobileMenu}>
                                            <span>Mobile</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/en/case-studies/custom-development`} onClick={closeMobileMenu}>
                                            <span>Custom Development</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/en/case-studies/content-creation`} onClick={closeMobileMenu}>
                                            <span>Content Creation</span>
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to={`/en/case-studies/social-media-management`} onClick={closeMobileMenu}>
                                            <span>Social Media Management</span>
                                        </Link>
                                    </li> */}
                                </ul>
                            </li>
                            <li>
                                <Link to={`/en/blog/`} onClick={closeMobileMenu}>
                                    <span>Blog</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/en/services/`} onClick={closeMobileMenu}>
                                    <span>Services</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/en/contact-us/`} onClick={closeMobileMenu}>
                                    <span>Contact Us</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderEN;
