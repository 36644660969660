import React from "react";
import { Link } from "gatsby";
import Layout from "../../../components/layout.en";
import SEO from "../../../components/seo";

const JamStackEN = () => (
    <Layout>
        <SEO title="JamStack Developers" lang="en" />

        <div className="bk-about-area section-ptb-100 mt-5" id="section-about">
            <div className="container">
                <div className="row about--creative align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="thumb text-left text-lg-left wow move-up">
                            <img src="/images/cherry-online-team-meeting-1.png" alt="jamstack" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt_md--40 mt_sm--40">
                        <div className="content pl--80 pl_md--5 pl_sm--5">
                            <div className="title--creative wow move-up">
                                <h2>
                                    Faster, secure
                                    <br />
                                    by nature <br /> <span className="theme-creative">JAMstack</span> developers
                                </h2>
                            </div>
                            <p className="wow move-up">We are helping companies</p>
                            <Link to="/en/contact-us" className="about-btn wow move-up">
                                <span>Work with us</span>
                                <i class="fa fa-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default JamStackEN;
