import React from "react";
import { Link } from "gatsby";

const FooterEN = () => {
    return (
        <footer className="page-footer bg_color--3 pl--150 pr--150 pl_lp--70 pr_lp--70 pl_lg--70 pr_lg--70 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">
            <div className="bk-footer-inner pt--150 pb--60 pt_md--90 pt_sm--90 pb_md--30 pb_sm--20">
                <div className="row">
                    <div className="col-lg-5 col-xl-7 col-md-6 col-sm-6 col-12">
                        <div className="footer-widget">
                            <div className="logo">
                                <Link to={`/en/`}>
                                    <img src="/images/cottonhat.png" style={{ width: 150 }} alt="footer Cottonhat logo" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-12 mt_mobile--40">
                        <div className="footer-widget menu--about">
                            <h2 className="widgettitle">Links</h2>
                            <div className="footer-menu">
                                <ul className="ft-menu-list bk-hover">
                                    <li>
                                        <Link to={`/en/services`}>Services</Link>
                                    </li>
                                    <li>
                                        <Link to={`/en/contact-us`}>Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                        <div className="footer-widget menu--contact">
                            <h2 className="widgettitle">Contact</h2>
                            <div className="footer-address bk-hover mb--20">
                                <p>
                                    <Link href="https://goo.gl/maps/vm3gtyZzC6jQnVNR9" target="_blank" rel="noreferrer">
                                        Rua Margarida de Abreu N 13, Esc 3
                                    </Link>
                                    <br /> Lisbon, Portugal
                                </p>
                                <p>
                                    <a href="mailto:info@cottonhat.net">info@cottonhat.net</a>
                                </p>
                                <p>
                                    <a href="tel:00351308814283">(+351) 308 814 283</a>
                                </p>
                            </div>
                            <div className="social-share social--transparent text-white">
                                <a href="https://www.facebook.com/cottonhat" target="_blank" rel="noreferrer">
                                    <i className="fab fa-facebook"></i>
                                </a>
                                <a href="https://www.instagram.com/cottonhat_studio/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-instagram"></i>
                                </a>

                                <a href="https://www.behance.net/cottonhat" target="_blank" rel="noreferrer">
                                    <i className="fab fa-behance"></i>
                                </a>

                                <a href="https://www.linkedin.com/company/cottonhat/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                                {/* <a href="https://www.twitter.com/cottonhat" target="_blank" rel="noreferrer">
                                    <i className="fab fa-twitter"></i>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright ptb--50">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="copyright-left text-left">
                            <ul className="bk-copyright-menu d-flex justify-content-center justify-content-md-start bk-hover">
                                <li>
                                    <Link to={`/en/privacy`}>Privacy</Link>
                                </li>
                                <li>
                                    <Link to={`/en/case-studies`}>Latest projects</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="copyright-right text-center text-md-right">
                            <p>
                                © 2021 <Link to={`/en/`}>Cottonhat.</Link> All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterEN;
