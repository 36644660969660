import React from "react";
import { Link } from "gatsby";

const HeaderEN = () => {
    return (
        <header class="br_header header-default one-page-header light-logo-version headroom--sticky clearfix">
            <div class="header-wrapper">
                <div class="header-left-wrapper">
                    <div class="branding">
                        <div class="logo">
                            <Link to="/en/">
                                <img style={{ height: 80, width: "auto" }} src="/images/logo_02.png" alt="Cottonhat Logo" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="header-right-wrapper d-none d-sm-block">
                    <div class="header-text text-right">
                        <h5 class="heading heading-h5 font-20 text-white">
                            <span class="fa fa-heart"></span> Hello, we are Cottonhat !
                        </h5>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderEN;
